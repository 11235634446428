<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-5 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4" color="#f9c99e">
                    Login Operator
                  </h1>
                </div>
                <v-form lazy-validation>
                  <v-text-field
                    label="Email"
                    color="grey darken-4"
                    name="email"
                    prepend-icon="mdi mdi-account"
                    type="text"
                    required
                    v-model="form.email"
                    :error="error"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password"
                    color="grey darken-4"
                    name="password"
                    prepend-icon="mdi mdi-lock"
                    required
                    v-model="form.password"
                    :rules="[rules.required]"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"
                    v-on:keyup="validateKey"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  block
                  color="grey darken-4"
                  dark
                  @click="login()"
                  :loading="loading"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        :color="color"
        :multi-line="true"
        :timeout="3000"
      >
        {{ text }}
        <v-btn dark text @click="snackbar = false">Tutup</v-btn>
      </v-snackbar>
    </v-main>
    <v-footer padless fixed>
      <v-col class="text-center">
        {{ new Date().getFullYear() }} —
        <!-- <a href="https://github.com/niusindra" target="_blank"
          >github.com/niusindra</a -->
          <strong>Pengurus Pusat Hapkido Indonesia</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

  // Add the Firebase products that you want to use
  import firebase from "firebase";
  import "firebase/auth";

  export default {
    data() {
      return {
        loading: false,
        load: false,
        snackbar: false,
        hidePassword: true,
        error: false,
        color: null,
        text: "",
        rules: {
          required: (value) => !!value || "Required.",
        },
        form: {
          email: null,
          password: null,
        },
        // user: new FormData(),
        pegawai: [],
        typeInput: "new",
        errors: "",
      };
    },
    props: {
      source: String,
    },

    beforeCreate: function() {
      if (this.$session.exists()) {
        this.$router.push({ name: "Final" });
      }
    },

    methods: {
      login() {
        firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          alert('Successfully logged in');
          this.$router.push('/final');
          
        })
        .catch(error => {
          alert(error.message);
        });


        firebase.auth().onAuthStateChanged(user => {
          if (user) {
              // User is signed in, see docs for a list of available properties
              this.$session.start();
              this.$session.set("fetchUser", user);
            } else {
              // User is signed out
            }

          
        });

      },
      validateKey(e) {
        if (e.keyCode === 13) {
          this.login();
        }
      },
    },
  };
</script>
